import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { AiOutlineClose } from 'react-icons/ai';

import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';

import LocationIcon from '@material-ui/icons/LocationCity';
import { Divider } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    width: '80vw',
    backgroundColor: theme.palette.background.default,
    borderRadius: '5px',
    margin: theme.spacing(2, 2, 2),
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 1, 2),
    maxHeight: '90vh',
  },
  locationContent: {
    overflowY: 'auto',
    maxHeight: '80vh',
  },
  locationText: {
    width: '100%',
    minWidth: 'unset',
    '& > a': {
      display: 'flex',
      alignItems: 'center',
      '& > span': {
        paddingLeft: '0.75rem',
      },
    },
  },
  root: {
    width: '100%',
    backgroundColor: theme.palette.background.default,
  },
}));

export default function LocationModal({ locations, callModalState, isOpen }) {
  const classes = useStyles();

  useEffect(() => {
    if (isOpen) callModalState(true);
    else callModalState(false);
  }, [isOpen]);

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className={classes.modal}
      open={isOpen}
      onClose={() => callModalState(false)}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={isOpen}>
        <div className={classes.paper}>
          <div id="transition-modal-title" style={{ paddingBottom: '1rem' }}>
            <h2>
              Locations
              <AiOutlineClose style={{ float: 'right', fontSize: '14px' }} onClick={() => callModalState(false)} />
            </h2>
          </div>
          <Divider />
          <div className={classes.locationContent}>
            {locations.nodes.map((location) => {
              if (!location.slug?.current?.includes('test') && location.phone) {
                return (
                  <div key={location.id} className={classes.root}>
                    <List component="nav" aria-label="main location">
                      <ListItem button>
                        <ListItemIcon className={classes.locationText}>
                          <a href={`tel:${location.phone}`}>
                            <LocationIcon />
                            <span>
                              {location.phone}
                              {location.address && (
                                <>
                                  <br />
                                  {location.address}
                                </>
                              )}
                              {location.city && (
                                <>
                                  <br />
                                  {location.city}
                                </>
                              )}
                            </span>
                          </a>
                        </ListItemIcon>
                      </ListItem>
                    </List>
                  </div>
                );
              }
            })}
          </div>
        </div>
      </Fade>
    </Modal>
  );
}

LocationModal.propTypes = {
  locations: PropTypes.object.isRequired,
  callModalState: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
};
