/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React, { useEffect, useState, createContext } from 'react';
import { graphql, StaticQuery } from 'gatsby';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import Contact from './sections/cycle/Contact';
import Footer from './sections/cycle/Footer';
import LocationModal from './location-modal';
import RentalModal from './rental-modal';
import BookingBar from './booking-bar';
import { ThemeProvider } from 'styled-components';
import theme from '../theme/cycle';
import { GlobalStyle } from './sections/cycle/cycle.style';

const isBrowser = typeof window !== 'undefined';

export const SiteContext = createContext({
  isCallOpen: false,
  setIsCallOpen: () => {},
  isRentalOpen: false,
  setIsRentalOpen: () => {},
});

const Cyclelayout = (props) => {
  const { children, path } = props;
  const [scroll, setScroll] = useState(false);
  const [isCallOpen, setIsCallOpen] = useState(false);
  const [isRentalOpen, setIsRentalOpen] = useState(false);
  const [isSearchPage, setIsSearchPage] = useState(false);

  const value = {
    isCallOpen,
    setIsCallOpen,
    isRentalOpen,
    setIsRentalOpen,
  };

  useEffect(() => {
    if (isBrowser) {
      const onScroll = (e) => {
        window.pageYOffset > 80 ? setScroll(true) : setScroll(false);
      };
      window.addEventListener('scroll', onScroll);
      return () => window.removeEventListener('scroll', onScroll);
    }
  }, [scroll]);

  useEffect(() => {
    if (path.includes('search_results')) {
      setIsSearchPage(true);
    } else {
      setIsSearchPage(false);
    }
  }, [path]);
  return (
    <StaticQuery
      query={graphql`
        query {
          locations: allSanityLocation(filter: { slug: { current: { ne: null } } }) {
            nodes {
              ...Location
            }
          }
        }
      `}
      render={({ locations }) => {
        return (
          <SiteContext.Provider value={value}>
            <ThemeProvider theme={theme}>
              <Helmet>
                <html lang="en" />
                <title>{`Rides N Motion`}</title>
                <script async src="https://fareharbor.com/embeds/api/v1/?autolightframe=yes" />`
                <script async src="https://widget.taggbox.com/embed.min.js" />
                <script
                  defer
                  src="https://connect.podium.com/widget.js#ORG_TOKEN=3b334c16-65d8-4527-9b43-10adb3139a58"
                  id="podium-widget"
                  data-organization-api-token="3b334c16-65d8-4527-9b43-10adb3139a58"
                />
                <script>
                  {`(function(config) {
                  window._peekConfig = config || {};
                  var idPrefix = 'peek-book-button';
                  var id = idPrefix+'-js'; if (document.getElementById(id)) return;
                  var head = document.getElementsByTagName('head')[0];
                  var el = document.createElement('script'); el.id = id;
                  var date = new Date; var stamp = date.getMonth()+"-"+date.getDate();
                  var basePath = "https://js.peek.com";
                  el.src = basePath + "/widget_button.js?ts="+stamp;
                  head.appendChild(el); id = idPrefix+'-css'; el = document.createElement('link'); el.id = id;
                  el.href = basePath + "/widget_button.css?ts="+stamp;
                  el.rel="stylesheet"; el.type="text/css"; head.appendChild(el);
                })({key: '293e0baa-0afb-4361-bb00-df4c2815b6d1'});`}
                </script>
                <meta name="facebook-domain-verification" content="52blxhbi7nk0vdp3qvq9ytz7rfjfd3" />
              </Helmet>
              <GlobalStyle />
              <div className={`page-wrapper ${scroll ? 'scrolled' : ''}`}>
                {children}
                {!isSearchPage && (
                  <>
                    <div className="taggbox-container" style={{ width: '100%', height: '100%', overflow: 'auto' }}>
                      <div
                        className="taggbox-socialwall"
                        data-wall-id={54486}
                        view-url="https://widget.taggbox.com/54486"
                      />
                    </div>
                  </>
                )}
                <Contact />
                <Footer />
                <BookingBar handleCallOpen={() => setIsCallOpen(true)} handleRentalOpen={() => setIsRentalOpen(true)} />
              </div>
              <LocationModal locations={locations} callModalState={setIsCallOpen} isOpen={isCallOpen} />
              <RentalModal rentalModalState={setIsRentalOpen} isOpen={isRentalOpen} />
            </ThemeProvider>
          </SiteContext.Provider>
        );
      }}
    />
  );
};

Cyclelayout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Cyclelayout;
