const React = require('react');
const CycleLayout = require('./src/components/cyclelayout').default;

exports.onInitialClientRender = ({ props }) => {
  require('pathseg/pathseg'); // polyfill for svg rendering
};

// eslint-disable-next-line react/display-name
exports.wrapPageElement = ({ element, props }) => {
  // wrap page with layout so we can persist the instagram widget across page renders
  // to limit the number of requests to the taggbox api
  // props provide same data to Layout as Page element will get
  // including location, data, etc - you don't need to pass it
  return <CycleLayout {...props}>{element}</CycleLayout>;
};
