import styled from 'styled-components';

const ContactWrapper = styled.div`
  position: relative;
  z-index: 99;
  text-align: center;
  padding: 220px 0;
  background-size: cover;
  &:before {
    content: '';
    height: 100%;
    width: 100%;
    position: absolute;
    left: 0;
    top: 0;
    background: linear-gradient(90deg, rgba(253, 81, 85, 1) 0%, rgba(113, 186, 4, 1) 100%);
    opacity: 0.8;
  }
  .subscribe-from-block {
    position: relative;
    z-index: 2;
  }
  .section-title {
    padding: 0 20%;
    margin-bottom: 55px;
    h2 {
      margin-bottom: 25px;
      font-size: 44px;
      font-weight: 600;
    }
    h2,
    p {
      color: ${(props) => props.theme.white};
    }
    p {
      font-size: 18px;
      line-height: 22px;
    }
  }
  form {
    width: 100%;
  }
  .form-elements {
    width: 60%;
    margin: 0 auto;
    position: relative;
  }
  input {
    display: block;
    padding: 15px;
    width: 100%;
    height: 60px;
    font-size: 16px;
    border-radius: 5px;
    margin: 0 auto 0px auto;
    box-shadow: none;
    transition: 450ms all;
    border: 1px solid transparent;
  }
  button {
    background: transparent;
    border: none;
    color: ${(props) => props.theme.primaryColor};
    height: 100%;
    position: absolute;
    top: 2px;
    right: 0;
    width: 80px;
    cursor: pointer;
    svg {
      font-size: 25px;
      transition: all 0.3s ease-in;
    }
    &:hover {
      svg {
        color: ${(props) => props.theme.secondaryColor};
      }
    }
  }
  @media only screen and (max-width: 912px) {
    padding: 80px 0;
    form {
      padding: 0px;
      input {
        width: 100%;
      }
    }
    .form-elements {
      width: 100%;
    }
    .section-title {
      padding: 0 5%;
      h2 {
        font-size: 35px;
        line-height: 43px;
      }
    }
  }
  @media only screen and (max-width: 480px) {
    form {
      padding: 30px 15px;
      input {
        width: 100%;
      }
    }
  }
`;

export default ContactWrapper;
