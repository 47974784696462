import React from 'react';
import styled, { css } from 'styled-components';

const RowWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-left: -15px;
  margin-right: -15px;
  ${(props) =>
    props.Vcenter &&
    css({
      'align-items': 'center',
    })};
  ${(props) =>
    props.Hcenter &&
    css({
      'justify-content': 'center',
    })};

  @media only screen and (max-width: 912px) {
    ${(props) =>
      props.reverseMobile &&
      css({
        'flex-wrap': 'wrap-reverse',
      })};
  }
`;

const Row = ({ children, ...props }) => {
  return <RowWrapper {...props}>{children}</RowWrapper>;
};

export default Row;
