import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`

::selection {
    background: #333333;
    color: #ffffff;
  }

  *,
  *::before,
  *::after {
    box-sizing: inherit;
  }

  * {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  *:focus {
    outline: none;
  }

html{
    box-sizing: border-box;
    -ms-overflow-style: scrollbar;
}

body,html {
    margin: 0;
}
body {
  font-size: 16px;
  line-height: 28px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: 'Poppins', sans-serif;
  color: ${(props) => props.theme.textColor};
  overflow-x: hidden !important;
  font-weight: 400;
  margin: 0;
  padding: 0;
}
h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: 'Poppins', sans-serif;
    color: ${(props) => props.theme.headingColor};
    margin: 0;
}

p{
    margin: 0 0 15px 0;
}

a{
    text-decoration: none;
    color: ${(props) => props.theme.headingColor};
  }
  img{
    max-width: 100%;
  }
section{
    position: relative;
}
.gradient-text {
    background-image: linear-gradient(45deg, #F3676D, #FEC945);
    background-size: 100%;
    background-repeat: repeat;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    -moz-background-clip: text;
    -moz-text-fill-color: transparent;
}
@keyframes bounce {
      from {
          transform: translateY(0px);
      }
      to {
          transform: translateY(-20px);
      }
      }
      @-webkit-keyframes bounce {
      from {
          transform: translateY(0px);
      }
      to {
          transform: translateY(-20px);
      }
}

@-webkit-keyframes pulse {
  0% {
    -webkit-box-shadow: 0 0 0 0 rgba(243,103,109, 0.4);
  }
  70% {
      -webkit-box-shadow: 0 0 0 10px rgba(243,103,109, 0);
  }
  100% {
      -webkit-box-shadow: 0 0 0 0 rgba(243,103,109, 0);
  }
}

@keyframes pulse {
  0% {
    -moz-box-shadow: 0 0 0 0 rgba(243,103,109, 0.4);
    box-shadow: 0 0 0 0 rgba(243,103,109, 0.4);
  }
  70% {
      -moz-box-shadow: 0 0 0 10px rgba(243,103,109, 0);
      box-shadow: 0 0 0 10px rgba(243,103,109, 0);
  }
  100% {
      -moz-box-shadow: 0 0 0 0 rgba(243,103,109, 0);
      box-shadow: 0 0 0 0 rgba(243,103,109, 0);
  }
}

.bounce-element{
    animation: bounce 1s infinite alternate;
    -webkit-animation: bounce 1s infinite alternate;
}

.pulse-element{
  animation: pulse 2s infinite;
  -webkit-animation: pulse 2s infinite;
  &:hover{
    animation: none;
  }
}

.scrolled{
  .bm-burger-button{
    .bm-burger-bars{
      background: ${(props) => props.theme.white} !important;
    }
    &:hover{
      .bm-burger-bars{
        background: ${(props) => props.theme.white} !important;
      }
    }
  }
}

`;

export { GlobalStyle };
