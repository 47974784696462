import React from 'react';
import { Link } from 'gatsby';
import PropTypes from 'prop-types';
import { AiOutlineClose } from 'react-icons/ai';

import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import { Divider } from '@material-ui/core';

import * as serializerStyles from './serializers.module.css';

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    maxWidth: '500px',
    margin: '0 auto',
  },
  paper: {
    width: '80vw',
    backgroundColor: theme.palette.background.default,
    borderRadius: '5px',
    margin: theme.spacing(2, 2, 2),
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 1, 2),
  },
  root: {
    backgroundColor: theme.palette.background.default,
    '& a': {
      width: '100%',
      maxWidth: '300px',
      display: 'unset',
      textAlign: 'center',
    },
  },
}));

export default function RentalModal({ rentalModalState, isOpen }) {
  const classes = useStyles();

  React.useEffect(() => {
    if (isOpen) rentalModalState(true);
    else rentalModalState(false);
  }, [isOpen]);

  return (
    <Modal
      disableEnforceFocus
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className={classes.modal}
      open={isOpen}
      onClose={() => rentalModalState(false)}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={isOpen}>
        <div className={classes.paper}>
          <div id="transition-modal-title" style={{ paddingBottom: '1rem', textAlign: 'center' }}>
            <h2>
              Book Rental
              <AiOutlineClose style={{ float: 'right', fontSize: '14px' }} onClick={() => rentalModalState(false)} />
            </h2>
          </div>
          <Divider />
          <div className={classes.root}>
            <List component="nav" aria-label="main location">
              <ListItem button>
                <a
                  href="https://book.peek.com/s/844bbed6-87a5-434e-9562-940416811757/P36JZ"
                  className={serializerStyles.button}
                  onClick={() => rentalModalState(false)}
                >
                  DC Ranch Scottsdale
                </a>
              </ListItem>

              <ListItem button>
                <a
                  href="https://book.peek.com/s/4fed25c3-5d96-469a-9f55-a6866833a106/qzB9v"
                  className={serializerStyles.button}
                  onClick={() => rentalModalState(false)}
                >
                  McCormick Ranch Scottsdale
                </a>
              </ListItem>

              <ListItem button>
                <a
                  href="https://book.peek.com/s/7775015b-f5c7-4d46-bb78-e221970ff2cc/loddk"
                  className={serializerStyles.button}
                  onClick={() => rentalModalState(false)}
                >
                  Desert Ridge Phoenix
                </a>
              </ListItem>

              <ListItem button>
                <a
                  href="https://book.peek.com/s/ca8483d1-34c3-474e-9222-f0a273795ae5/em7vP"
                  className={serializerStyles.button}
                  onClick={() => rentalModalState(false)}
                >
                  Peoria
                </a>
              </ListItem>

              <ListItem button>
                <a
                  href="https://book.peek.com/s/293e0baa-0afb-4361-bb00-df4c2815b6d1/xpkNr"
                  className={serializerStyles.button}
                  onClick={() => rentalModalState(false)}
                >
                  Flagstaff
                </a>
              </ListItem>

              <ListItem button>
                <a
                  href="https://fareharbor.com/embeds/book/ridesnmotion-bayhead/items/393379/?full-items=yes&flow=777638"
                  className={serializerStyles.button}
                  onClick={() => rentalModalState(false)}
                >
                  Bay Head NJ
                </a>
              </ListItem>

              <ListItem button>
                <Link to="/san-diego" className={serializerStyles.button} onClick={() => rentalModalState(false)}>
                  San Diego
                </Link>
              </ListItem>
            </List>
          </div>
        </div>
      </Fade>
    </Modal>
  );
}

RentalModal.propTypes = {
  rentalModalState: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
};
