import React from 'react';
import { graphql, Link, StaticQuery } from 'gatsby';

import { FiPhoneCall } from 'react-icons/fi';
import { FaFacebookF, FaInstagram } from 'react-icons/fa';
import { Col, Container, Row } from '../../../../components/reusecore/Layout';
import Box from '../../../../components/reusecore/Box';
import Heading from '../../../../components/reusecore/Heading';
import Text from '../../../../components/reusecore/Text';
import { List, ListItem } from '../../../reusecore/List';

import FooterWrapper from './footer.style';
import MenuLink from '../../../layout/menu-link';
import { StaticImage } from 'gatsby-plugin-image';

const Footer = () => {
  return (
    <StaticQuery
      query={graphql`
        query {
          site: sanitySiteSettings(_id: { eq: "siteSettings" }) {
            socialMediaFacebookUrl
            socialMediaInstagramUrl
            contactPhoneNumber
            contactEmail
          }
          locations: allSanityLocation(filter: { slug: { current: { ne: null } } }) {
            nodes {
              ...Location
            }
          }
          footerMenuServices: sanityMenu(slug: { current: { eq: "footer-menu-services" } }) {
            ...Menu
          }
          footerMenuAbout: sanityMenu(slug: { current: { eq: "footer-menu-about" } }) {
            ...Menu
          }
        }
      `}
      render={({ site, footerMenuServices, footerMenuAbout, logo, locations }) => {
        const footerMenuServicesLinks = footerMenuServices ? footerMenuServices.links : null;
        const footerMenuAboutLinks = footerMenuAbout ? footerMenuAbout.links : null;
        const { socialMediaFacebookUrl, socialMediaInstagramUrl, contactPhoneNumber, contactEmail } = site;

        return (
          <FooterWrapper>
            <Container>
              <Row>
                <Col xs={12} sm={6} lg={3}>
                  <Box className="footer-widgets first">
                    <Link to="/" className="footer-logo">
                      <StaticImage
                        loading="eager"
                        placeholder="none"
                        src="../../../../assets/images/RnMLogoBlue_white.png"
                        alt="Rides N Motion logo"
                      />
                    </Link>
                    <List className="info">
                      <ListItem>
                        <a href={`mailto:${contactEmail}`}>{contactEmail}</a>
                      </ListItem>
                      <ListItem>
                        <FiPhoneCall />
                        <a href={`tel:${contactPhoneNumber}`}>{contactPhoneNumber}</a>
                      </ListItem>
                    </List>

                    <List className="social">
                      <ListItem>
                        <a href={socialMediaFacebookUrl} target="_blank">
                          {' '}
                          <FaFacebookF />{' '}
                        </a>
                      </ListItem>
                      <ListItem>
                        <a href={socialMediaInstagramUrl} target="_blank">
                          {' '}
                          <FaInstagram />{' '}
                        </a>
                      </ListItem>
                    </List>
                  </Box>
                </Col>
                <Col xs={12} sm={6} lg={3}>
                  <Box className="footer-widgets">
                    <Heading as="h3" className="widget-title">
                      Services
                    </Heading>
                    <List className="widget-catagory">
                      {footerMenuServicesLinks.map((link) => {
                        return (
                          <ListItem key={link._key}>
                            <MenuLink link={link} />
                            {link.subItems && link.subItems.length > 0 && (
                              <div className="subItemMenu">
                                {link.subItems.map((subLink) => {
                                  return (
                                    <div key={subLink._key}>
                                      <MenuLink className="menu-item" link={subLink} />
                                    </div>
                                  );
                                })}
                              </div>
                            )}
                          </ListItem>
                        );
                      })}
                    </List>
                  </Box>
                </Col>
                <Col xs={12} sm={6} lg={3}>
                  <Box className="footer-widgets">
                    <Heading as="h3" className="widget-title">
                      About Us
                    </Heading>
                    <List className="widget-catagory">
                      {footerMenuAboutLinks.map((link) => {
                        return (
                          <ListItem key={link._key}>
                            <MenuLink link={link} />
                            {link.subItems && link.subItems.length > 0 && (
                              <div className="subItemMenu">
                                {link.subItems.map((subLink) => {
                                  return (
                                    <div key={subLink._key}>
                                      <MenuLink className="menu-item" link={subLink} />
                                    </div>
                                  );
                                })}
                              </div>
                            )}
                          </ListItem>
                        );
                      })}
                    </List>
                  </Box>
                </Col>
                <Col xs={12} sm={6} lg={3}>
                  <Box className="footer-widgets">
                    <Heading as="h3" className="widget-title">
                      Our Locations
                    </Heading>
                    {locations.nodes.map((location) => {
                      if (!location.slug?.current?.includes('test')) {
                        return (
                          <Text key={location.slug.current}>
                            <Link to={`/${location.slug.current}`}>{location.title}</Link>
                          </Text>
                        );
                      }
                    })}
                  </Box>
                </Col>
              </Row>
              <Box className="footer-bottom">
                <List className="footer-menu">
                  <ListItem>
                    <Link to="/terms"> Terms and Conditions </Link>
                  </ListItem>
                  <ListItem>
                    <Link to="/privacy"> Privacy Policy </Link>
                  </ListItem>
                  <ListItem>
                    <Link to="/faqs"> FAQs </Link>
                  </ListItem>
                  <ListItem>
                    <Link to="/contact"> Contact </Link>
                  </ListItem>
                </List>
                <Text className="copyright-text">
                  Copyright {new Date().getFullYear()} <Link to="/"> Rides N Motion </Link>| All Right Reserved
                </Text>
              </Box>
            </Container>
          </FooterWrapper>
        );
      }}
    />
  );
};

export default Footer;
