import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import Button from '@material-ui/core/Button';
import { formContainer, dangerMsgStyle } from './subscriptionForm.module.css';
import { FiSend } from 'react-icons/fi';
import Box from '../../../reusecore/Box';

const isBrowser = typeof window !== 'undefined';
class SubscriptionForm extends React.Component {
  state = {
    email: '',
  };

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.message !== this.props.message && this.state.email) {
      if (this.props.message === 'captcha') {
        isBrowser && window.open(`${this.props.url}&EMAIL=${encodeURIComponent(this.state.email)}`);
        this.setState({
          email: '',
        });
      }
    }
  }

  handleInputChange = (event) => {
    event.preventDefault();

    const target = event.target;
    const value = target.value;
    this.setState({
      email: value,
    });
  };

  submit = (e) => {
    e.preventDefault();

    const { email } = this.state;

    if (email) {
      const { status, message, onValidated } = this.props;
      return (
        email &&
        email.indexOf('@') > -1 &&
        onValidated({
          EMAIL: email,
        })
      );
    }
  };

  render() {
    const { classes, status, message, onValidated } = this.props;

    return (
      <div>
        <div style={{ textAlign: 'center' }}>{status === 'success' ? 'Thank you for subscribing!' : null}</div>
        <br />
        <br />
        <form onSubmit={(e) => this.submit(e)}>
          <div className={formContainer}>
            {status === 'sending' ? (
              <div style={{ textAlign: 'center' }}>
                <CircularProgress color="primary" />
              </div>
            ) : (
              <Box className="form-elements">
                <input
                  disabled={status === 'sending'}
                  name="email"
                  type="email"
                  placeholder="Enter your Email"
                  onChange={this.handleInputChange}
                />
                <Button disabled={status === 'sending'} onClick={(e) => this.submit(e)}>
                  <FiSend />
                </Button>
              </Box>
            )}
          </div>
          {status === 'error' && message !== 'captcha' && (
            <>
              <br />
              <div className={dangerMsgStyle} dangerouslySetInnerHTML={{ __html: message }} />
            </>
          )}
        </form>
      </div>
    );
  }
}

export default SubscriptionForm;
