const theme = {
  black: '#231f20',
  limeDark: '#252525',
  white: '#ffffff',
  primaryColor: '#ffffff',
  primaryLightColor: '#c9c6c5',
  primaryLightColorTwo: '#a8a9a7',
  secondaryColor: '#416eb6',
  secondaryLightColor: '#c67275',
  tertiaryColor: '#6c191c',
  headingColor: '#30323E',
  textColor: '#231f20',
  shadowColor: 'rgba(0, 0, 0, 0.05)',
  shadowLightColor: '#E6E6E6',
  menuColor: '#416eb6',
  menuHoverColor: '#ffffff',
};
export default theme;
