import React from 'react';
import { Link } from 'gatsby';
import { FaListUl, FaCartPlus, FaPhoneAlt, FaTag } from 'react-icons/fa';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const Item = styled.a`
  padding-top: 20px;
  padding-bottom: 10px;
  font-size: 16px;
  text-align: center;
  background: ${(props) => (props.bookTour ? 'var(--secondary-primary)' : '#2D373F')};
  color: var(--color-white);
`;
const ItemLink = styled(Link)`
  padding-top: 20px;
  padding-bottom: 10px;
  font-size: 16px;
  text-align: center;
  background: #2d373f;
  color: var(--color-white);
`;
export const Section = styled.section`
  position: fixed;
  bottom: 0;
  width: 100%;
  line-height: 1.5;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: 1fr;
  grid-column-gap: 0;
  grid-row-gap: 0;
  z-index: 998;
  @media (min-width: 500px) {
    display: none;
  }
`;

export default function BookingBar({ handleCallOpen, handleRentalOpen }) {
  return (
    <Section className="booking-bar">
      <ItemLink to="/locations">
        <FaListUl />
        <p>Locations</p>
      </ItemLink>
      <ItemLink to="/shop">
        <FaCartPlus />
        <p>Shop Store</p>
      </ItemLink>
      <Item onClick={handleCallOpen}>
        <FaPhoneAlt />
        <p>Call Now</p>
      </Item>
      <Item bookTour onClick={handleRentalOpen}>
        <FaTag />
        <p>Book Rental</p>
      </Item>
    </Section>
  );
}

BookingBar.propTypes = {
  handleCallOpen: PropTypes.func.isRequired,
  handleRentalOpen: PropTypes.func.isRequired,
};
