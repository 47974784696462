import React from 'react';
import Box from '../../../reusecore/Box';

import { Container } from '../../../reusecore/Layout';
import SectionTitle from '../../../reusecore/SectionTitle';
import Heading from '../../../reusecore/Heading';
import Text from '../../../reusecore/Text';

import MailchimpSubscribe from 'react-mailchimp-subscribe';

import ContactWrapper from './contact.style';
import SubscriptionForm from './SubscriptionForm';
import { GatsbyImage } from 'gatsby-plugin-image';
import { graphql, StaticQuery } from 'gatsby';
import styled from 'styled-components';

const url = 'https://phatridesusa.us7.list-manage.com/subscribe/post?u=cff6e7c649f0f1f4015e12e03&amp;id=2e8e979ebb';

const BgImage = styled(GatsbyImage)`
  position: absolute !important;
  top: 0;
  left: 0;
  width: 100%;
  bottom: 0;
  z-index: -1;

  & > img {
    object-fit: cover !important;
    object-position: 0 0 !important;
  }
`;

const Contact = () => {
  return (
    // eslint-disable-next-line react/jsx-no-undef
    <StaticQuery
      query={graphql`
        query {
          bgImage: file(relativePath: { eq: "group-photo-lake.jpg" }) {
            childImageSharp {
              gatsbyImageData(quality: 100, layout: FULL_WIDTH)
            }
          }
        }
      `}
      render={({ bgImage }) => (
        <ContactWrapper id="contact">
          <BgImage image={bgImage?.childImageSharp?.gatsbyImageData} alt="Group photo on bikes" />
          <Container>
            <Box className="subscribe-from-block">
              <SectionTitle className="section-title" UniWidth="100%">
                <Heading as="h2">Subscribe to our newsletter</Heading>
                <Text>Subscribe to our newsletter to get latest news about our products, events and sales.</Text>
              </SectionTitle>
              <MailchimpSubscribe
                url={url}
                render={({ subscribe, status, message }) => (
                  <SubscriptionForm
                    url={url}
                    status={status}
                    message={message}
                    onValidated={(formData) => {
                      subscribe(formData);
                    }}
                  />
                )}
              />
            </Box>
          </Container>
        </ContactWrapper>
      )}
    />
  );
};

export default Contact;
