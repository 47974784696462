// dynamic link component that uses Link for internal and anchors for external hrefs
import React from 'react';
import { Link } from 'gatsby';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export default function MenuLink({ link: { href, title, hidden, page }, icon }) {
  if (hidden) {
    return null;
  }

  let content = () => (
    <>
      {icon && <FontAwesomeIcon icon={icon.svg} title={icon.title} className={icon.className} />}
      {title}
    </>
  );

  if (href) {
    if (href.indexOf('http') === 0) {
      return (
        <a className="menu-item" target="_blank" rel="noopener" href={href} title={title}>
          {content()}
        </a>
      );
    } else {
      return (
        <Link className="menu-item" to={href} title={title}>
          {content()}
        </Link>
      );
    }
  } else if (page.slug) {
    if (page.slug.current === 'home') {
      return (
        <Link className="menu-item" to={'/'} title={title}>
          {content()}
        </Link>
      );
    }
    return (
      <Link className="menu-item" to={`/${page.slug.current}`} title={title}>
        {content()}
      </Link>
    );
  }

  return (
    <a className="menu-item" rel="noopener" href="" title={title}>
      {content()}
    </a>
  );
}
